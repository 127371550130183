import React, { useEffect, useState } from 'react';
import './OrderDetailPage.css';
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from "../components/TitleComponent";
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { FullOrder } from '../interfaces/OrderInterfaces';
import OrderComponent from '../components/OrderComponent';
import { Card, Table, TableHead, TableBody, TableCell, TableRow, TableHeaderCell, Metric, Button, Flex } from '@tremor/react';
import  PDFStickerComponent from '../components/PDFStickerComponent';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Drivers } from '../interfaces/UserInterfaces';

export default function OrderDetailPage({drivers}:{drivers:Drivers}) {
    const [cookies] = useCookies(['mazelog-user']);
    const productFlag = cookies['mazelog-user']?.organization_data?.product_flag;
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const statusTranslate: { [key: string]: string } = {
        "created": "Criado",
        "transporter_allocated": "Transportador alocado",
        "collected": "Coletado",
        "received": "Recebido",
        "en_route": "Em rota",
        "delivered": "Entregue",
        "cancelled": "Cancelado",
        "handling": "Em tratativa",
        "awaiting_return": "Aguard. devolução",
        "awaiting_transfer": "Aguard. transferência",
        "return_route": "Rota devolução",
        "returned": "Devolvido",
        "collected_not_received": "Coletados Não Receb.",
        "cancelled_after_received": "Canc. após Receb.",
        "returned_area_not_serviced": "Devolvido - Área não atendida",
        "new_driver_allocated": "Novo entregador alocado"
    }

    let { id } = useParams();
    const [order, setOrder] = useState<FullOrder>();
    const [orderUpdated, setOrderUpdated] = useState<boolean>(false);

    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;
    useEffect(()=>{
        axios.get(`${baseURL}/order/${id}`,
            {headers: {"Authorization": oauth}})
            .then((res) => {
                console.log(res);
                setOrder(res.data);
            })
            .catch((err) => {
                console.log(err);
                navigate(`/search`);
            })
    },[id,orderUpdated])

    const convertDate = (date:string) => {
        const pos = date.indexOf("+");
        if (pos !== -1) {
            date = date.substring(0, pos);
        }
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - 3);
        return newDate.toLocaleString('pt-BR');
    }

  return (
    <main>
        <SidebarComponent />
        <div className='OrderContent'>
            <TitleComponent />
            <div className='ButtonBlock'>
                <Flex justifyContent='end'>
                    {order ? 
                    (<div>
                        <Button className='DownloadBtn'>
                            <PDFDownloadLink
                                document={<PDFStickerComponent orderList={[order]} productFlag={productFlag} />}
                                fileName={`${order?.orderNumber}.pdf`}
                            >
                            Baixar etiqueta
                            </PDFDownloadLink>
                        </Button>
                        <Link to={`/occurrence/${order.orderNumber}`}>
                            <Button className='DownloadBtn'>Abrir ocorrência</Button>
                        </Link>
                    </div>) :
                    (<div>
                        <Button className='DownloadBtn' disabled={true}>Cancelar pedido</Button>
                        <Button className='DownloadBtn' disabled={true}>Abrir ocorrência</Button>
                    </div>)}
                </Flex>
            </div>
            <div className="DashboardViews">
                <OrderComponent order={order} />
            </div>
            <div>
                <Card>
                    <Metric>Histórico do pedido</Metric>
                    <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell>Data</TableHeaderCell>
                            <TableHeaderCell>Entregador</TableHeaderCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { order?.events
                            .map((event) => (
                                <TableRow key={ event?.type }>
                                    <TableCell className="TableField">{ statusTranslate[event?.type] }</TableCell>
                                    <TableCell className="TableField">{ convertDate(event?.date) }</TableCell>
                                    <TableCell className="TableField">
                                        { (event?.id_user) && (event?.id_user in drivers) ?
                                        drivers[event?.id_user].email : "" }
                                    </TableCell>
                                </TableRow>
                            )) }
                    </TableBody>
                </Table>
                </Card>
            </div>
            <br/>
            <div>
                <div >
                    {order ? 
                    (<div>
                        <Card>
                            <Metric>Etiqueta</Metric>
                            <PDFViewer className='PDFCard' showToolbar={false}>
                                <PDFStickerComponent orderList={[order]} productFlag={productFlag} />
                            </PDFViewer>
                        </Card>
                    </div>) :
                    ('')}
                </div>
            </div>
            <br/>
            <div>
                {(!!order?.collecterImage) ?
                <Card>
                    <Metric>Imagem da entrega</Metric>
                    <img src={order?.collecterImage} className='max-h-[600px] max-w-[600px]'/>
                </Card>
                 :
                ""
                }
            </div>
        </div>
    </main>
  );
}